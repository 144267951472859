<template>
  <div class="text-center text-white">
    <h1>PhoneBox Staff Log In</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cnum: "",
      token: "",
    };
  },
  mounted() {
    let url = window.location.href.split("/mgmt?")[1];
    if (url === "" || url === "?" || url === undefined) {
      this.$router.push("login");
    }
    let cnumStr = url.split("&")[0];
    let tokenStr = url.split("&")[1];
    if (cnumStr === undefined || tokenStr === undefined) {
      this.$router.push("/login");
    } else {
      this.cnum = cnumStr.split("=")[1];
      this.token = tokenStr.split("=")[1];
      this.$store.commit("setLogInStates", {
        key: "token",
        value: this.token,
      });
      this.$store.commit("setLogInStates", {
        key: "cnum",
        value: this.cnum,
      });
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          let clientType = data.Prepaid ? "prepaid" : "postpaid";
          this.$store.commit("setLogInStates", {
            key: "clientType",
            value: clientType,
          });
          if (clientType === "prepaid") {
            this.$router.push("/prepaid-home");
          } else {
            this.$router.push("/home");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("PhoneBox Staff Log In Error!");
        });
    }
  },
};
</script>

<style lang="scss" scoped></style>
